import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContentContainer from '../components/ContentContainer'
import StaggeredSvg from '../components/StaggeredSvg/index'
import BackgroundSvg from '../images/manstanding.svg'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

export default () => (
	<Layout>
		<SEO title="About" />

		<SvgContainer>
			<SvgHolder>
				<StaggeredSvg>
					<BackgroundSvg />
				</StaggeredSvg>
			</SvgHolder>
		</SvgContainer>

		<ContentContainer>
			<h1>About</h1>

			<p>I'm a <strong>software developer</strong> specialising in <strong>Node.js</strong> along with large portions of experience (and fun) working with <strong>React</strong> and <strong>React Native</strong>.</p>

			<p>Great lover of <OutboundLink href="https://github.com/jpwilliams/remit" target="_blank" rel="noreferrer noopener">event-driven architectures and open source</OutboundLink>. In my extra time I'm either learning Golang, learning Rust, or <OutboundLink href="https://www.instagram.com/p/B0qHTYEnfNw/" target="_blank" rel="noopener noreferrer">climbing!</OutboundLink></p>

			<p>You can find me at <OutboundLink href="https://github.com/jpwilliams" target="_blank" rel="noreferrer noopener">GitHub</OutboundLink>, <OutboundLink href="https://twitter.com/atticjack" target="_blank" rel="noreferrer noopener">Twitter</OutboundLink>, <OutboundLink href="https://www.linkedin.com/in/jackpwilliams/" target="_blank" rel="noreferrer noopener">LinkedIn</OutboundLink>, and <OutboundLink href="https://keybase.io/jackwilliams" target="_blank" rel="noopener noreferrer">Keybase</OutboundLink>.</p>

			<p>If you'd like to contact me directly, send an email to <a href="mailto:hello@jpwilliams.dev" target="_blank" rel="noreferrer noopener">hello@jpwilliams.dev</a>.</p>
		</ContentContainer>
	</Layout>
)

const SvgContainer = styled.div`
	position: relative;
	width: 100%;
	z-index: -1;
`

const SvgHolder = styled.div`
	position: absolute;
	width: 100%;
	margin-left: -70%;
	margin-top: -15rem;
`
